import React from "react";
import { Col, Form, Row, Card, Button } from "react-bootstrap";

const SignatureForm = ({ formData, onInputChange, IssuanceForm }) => {
    const handleChange = (e) => {
        const { name, type, checked, files } = e.target;
        const value = type === "checkbox" ? checked : files ? files[0] : e.target.value;
        onInputChange(name, value);
    };

    const handleFileClick = () => {
        document.getElementById("signatureUpload").click();
    };

    return (
        <div>
            <h5 className="fw-bold mb-4">{IssuanceForm ? 12 : 11}. Signature</h5>

            <Row className="mb-4 align-items-center">
                <Col xs={12} className="mb-3">
                    <Form.Label>Digital Signature</Form.Label>
                </Col>
                <Col xs={12} md={6} className="mb-4">
                    <Card style={{ backgroundColor: "#F5F5F5" }} className="p-3 text-center upload-card">
                        <Card.Body>
                            {formData.digitalSignatureFile ? (
                                <div>
                                    <p className="mb-2">Signature Uploaded:</p>
                                    <p className="text-truncate">{formData.digitalSignatureFile.name}</p>
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={() => onInputChange("digitalSignatureFile", null)}
                                    >
                                        Remove
                                    </Button>
                                </div>
                            ) : (
                                <div className="pointer" onClick={handleFileClick}>
                                    <p>Click to upload your signature</p>

                                    <Form.Control
                                        type="file"
                                        id="signatureUpload"
                                        name="digitalSignatureFile"
                                        accept=".png,.jpg,.jpeg,.pdf"
                                        onChange={handleChange}
                                        className="d-none"
                                    />
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="align-items-center">
                <Col xs={12}>
                    <Form.Check
                        type="checkbox"
                        name="acceptTerms"
                        label="By submitting this application, you acknowledge and agree that a non-performance bond amount will be placed on account until the financial instrument is received and verified. The specific terms and amount of this bond will be agreed upon once your application has been accepted to proceed."
                        id="acceptTerms"
                        className="blue-checkbox"
                        checked={formData.acceptTerms || false}
                        onChange={handleChange}
                        required
                    />
                </Col>
            </Row>
        </div>
    );
};

export default SignatureForm;
