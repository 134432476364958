import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { CountrySelect } from "react-country-state-city";
import PhoneInput from 'react-phone-number-input'

const ContactUsForm = ({ formData, onInputChange }) => {
    return (
        <div>

            <h5 className="fw-bold mb-4">2. Contact Data</h5>

            <Row className="mb-4 align-items-center">
                <Col md={3}>
                    <Form.Label>Residential Address</Form.Label>
                </Col>
                <Col md={9}>
                    <Form.Control
                        name="contactDataResidentialAddress"
                        type="text"
                        placeholder="Enter your residential address"
                        value={formData.contactDataResidentialAddress}
                        onChange={(e) => onInputChange("contactDataResidentialAddress", e.target.value)}
                        required
                    />
                </Col>
            </Row>

            {/* Street Address */}
            <Row className="mb-4 align-items-center">
                <Col md={3}>
                    <Form.Label>Street Address</Form.Label>
                </Col>
                <Col md={9}>
                    <Form.Control
                        name="ContactDataStreetAddress"
                        type="text"
                        placeholder="Enter your street address"
                        value={formData.ContactDataStreetAddress}
                        onChange={(e) => onInputChange("ContactDataStreetAddress", e.target.value)}
                        required
                    />
                </Col>
            </Row>

            {/* City */}
            <Row className="mb-4 align-items-center">
                <Col md={3}>
                    <Form.Label>City</Form.Label>
                </Col>
                <Col md={9}>
                    <Form.Control
                        name="ContactDataCity"
                        type="text"
                        placeholder="Enter your city"
                        value={formData.ContactDataCity}
                        onChange={(e) => onInputChange("ContactDataCity", e.target.value)}
                        required
                    />
                </Col>
            </Row>

            {/* State/Province */}
            <Row className="mb-4 align-items-center">
                <Col md={3}>
                    <Form.Label>State/Province</Form.Label>
                </Col>
                <Col md={9}>
                    <Form.Control
                        name="ContactDataState"
                        type="text"
                        placeholder="Enter your state/province"
                        value={formData.ContactDataState}
                        onChange={(e) => onInputChange("ContactDataState", e.target.value)}
                        required
                    />
                </Col>
            </Row>

            {/* Postal Code and Country */}
            <Row>
                <Col>
                    <Row className="mb-4 align-items-center">
                        <Col md={6}>
                            <Form.Label>Postal Code</Form.Label>
                        </Col>
                        <Col md={6}>
                            <Form.Control
                                name="ContactDataPostalCode"
                                type="text"
                                placeholder="Enter postal code"
                                value={formData.ContactDataPostalCode}
                                onChange={(e) => onInputChange("ContactDataPostalCode", e.target.value)}
                                required
                            />
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row className="mb-4 align-items-center">
                        <Col md={6}>
                            <Form.Label>Country</Form.Label>
                        </Col>
                        <Col md={6}>
                            <CountrySelect
                                required
                                placeHolder="Select Country"
                                value={formData.ContactDataCountry}
                                onChange={(value) => onInputChange("ContactDataCountry", value?.name)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>


            {/* Phone */}
            <Row className="mb-4 align-items-center">
                <Col md={3}>
                    <Form.Label>Phone</Form.Label>
                </Col>
                <Col md={9}>
                    <PhoneInput
                        name="ContactDataPhone"
                        type="text"
                        defaultCountry="US"
                        className="border-0"
                        placeholder="Enter your phone number"
                        value={formData.ContactDataPhone}
                        onChange={(value) => onInputChange("ContactDataPhone", value)}
                        required
                    />
                </Col>
            </Row>

            {/* Email */}
            <Row className="mb-4 align-items-center">
                <Col md={3}>
                    <Form.Label>Email</Form.Label>
                </Col>
                <Col md={9}>
                    <Form.Control
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        value={formData.ContactDataEmail}
                        onChange={(e) => onInputChange("ContactDataEmail", e.target.value)}
                        required
                    />
                </Col>
            </Row>
        </div>
    );
};

export default ContactUsForm;
