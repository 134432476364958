import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import PhoneInput from 'react-phone-number-input';
import { FileIcons } from "../assets/SVG";

const FIDForm = ({ formData, onInputChange, IssuanceForm }) => {
    const handleFileUpload = (key, e) => {
        const file = e.target.files[0];
        if (!file) return;

        const fileSizeLimit = 5 * 1024 * 1024;
        const allowedImageTypes = ["image/jpeg", "image/png"];
        const isImage = allowedImageTypes.includes(file.type);

        if (file.size > fileSizeLimit) {
            alert("File size exceeds 5MB limit.");
            return;
        }

        const fileData = {
            name: file.name,
            type: isImage ? "image" : "file",
            file,
        };

        if (isImage) {
            fileData.url = URL.createObjectURL(file);
        }

        onInputChange(key, fileData);
    };

    const [showOtherInput, setShowOtherInput] = useState({
        FinancialTypeOfInstrumentIssuance: false,
        FinancialPurposeOfInstrument: false,
        FinancialPurposeOfFunding: false,
        FinancialInstrumentCode: false,
    });

    const handleOtherInputToggle = (field, value) => {
        onInputChange(field, value);
        setShowOtherInput((prev) => ({
            ...prev,
            [field]: value === "Other",
        }));
    };

    return (
        <div>
            <h5 className="fw-bold mb-4">6. Financial Instrument Details</h5>
            <Row className="mb-4 align-items-center">
                <Col xs={12} md={3}>
                    <Form.Label>Type of Instrument</Form.Label>
                </Col>
                {IssuanceForm ? <>
                    <Col xs={12} md={9} className="d-flex flex-wrap">
                        {["BG", "SBLC", "LC", "Other"].map((type) => (
                            <Form.Check
                                key={type}
                                type="radio"
                                name="FinancialTypeOfInstrumentIssuance"
                                value={type}
                                label={type}
                                id={type}
                                checked={formData.FinancialTypeOfInstrumentIssuance === type}
                                onChange={(e) => handleOtherInputToggle("FinancialTypeOfInstrumentIssuance", e.target.value)}
                                className="me-3"
                                required
                            />
                        ))}
                        {showOtherInput?.FinancialTypeOfInstrumentIssuance && (
                            <Form.Control
                                type="text"
                                value={formData.OtherFinancialTypeOfInstrumentIssuance || ""}
                                onChange={(e) => onInputChange("OtherFinancialTypeOfInstrumentIssuance", e.target.value)}
                                className="mt-2"
                            />
                        )}
                    </Col>
                </>
                    :
                    <>
                        <Col xs={12} md={9} className="d-flex flex-wrap">
                            {["SBLC", "DLC", "BG", "MTN", "Bank Issued Bond"].map((type) => (
                                <Form.Check
                                    key={type}
                                    type="radio"
                                    name="FinancialTypeOfInstrument"
                                    value={type}
                                    label={type}
                                    id={type}
                                    checked={formData.FinancialTypeOfInstrument === type}
                                    onChange={(e) => handleOtherInputToggle("FinancialTypeOfInstrument", e.target.value)}
                                    className="me-3"
                                    required
                                />
                            ))}
                            {showOtherInput?.FinancialTypeOfInstrument && (
                                <Form.Control
                                    type="text"
                                    value={formData.OtherFinancialTypeOfInstrument || ""}
                                    onChange={(e) => onInputChange("OtherFinancialTypeOfInstrument", e.target.value)}
                                    className="mt-2"
                                />
                            )}
                        </Col>
                    </>}

            </Row>
            <Row className="mb-4 align-items-center">
                <Col xs={12} md={3}>
                    <Form.Label>Instrument Code (if applicable)</Form.Label>
                </Col>
                <Col xs={12} md={9} className="d-flex flex-wrap">
                    {["MT760", "MT799", "Other"].map((type) => (
                        <Form.Check
                            key={type}
                            type="radio"
                            name="FinancialInstrumentCode"
                            value={type}
                            label={type}
                            id={type}
                            checked={formData.FinancialInstrumentCode === type}
                            onChange={(e) => handleOtherInputToggle("FinancialInstrumentCode", e.target.value)}
                            className="me-3"
                            required
                        />
                    ))}
                    {showOtherInput?.FinancialInstrumentCode && (
                        <Form.Control
                            type="text"
                            value={formData.otherFinancialInstrumentCode || ""}
                            onChange={(e) => onInputChange("otherFinancialInstrumentCode", e.target.value)}
                            className="mt-2"
                        />
                    )}
                </Col>
            </Row>
            <Row className="mb-4 align-items-center">
                <Col xs={12} md={3}>
                    <Form.Label>Amount</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                    <Form.Control
                        type="number"
                        placeholder="Enter Bank Name"
                        value={formData.FinancialAmount || ""}
                        onChange={(e) => onInputChange("FinancialAmount", e.target.value)}
                        required
                    />
                </Col>
            </Row>


            {IssuanceForm && <>

                <Row className="mb-4 align-items-center">
                    <Col xs={12} md={3}>
                        <Form.Label>Purpose of the Instrument
                        </Form.Label>
                    </Col>
                    <Col xs={12} md={6} className="d-flex flex-wrap">
                        {["Trade Finance", "Project Finance", "Other"].map((type) => (
                            <Form.Check
                                key={type}
                                type="radio"
                                name="FinancialPurposeOfInstrument"
                                value={type}
                                label={type}
                                id={type}
                                checked={formData.FinancialPurposeOfInstrument === type}
                                onChange={(e) => handleOtherInputToggle("FinancialPurposeOfInstrument", e.target.value)}
                                className="me-3"
                                required
                            />
                        ))}
                        {showOtherInput?.FinancialPurposeOfInstrument && (
                            <Form.Control
                                type="text"
                                value={formData.OtherFinancialPurposeOfInstrument || ""}
                                onChange={(e) => onInputChange("OtherFinancialPurposeOfInstrument", e.target.value)}
                                className="mt-2"
                            />
                        )}
                    </Col>
                </Row>
                <Row className="mb-4 align-items-center">
                    <Col xs={12} md={3}>
                        <Form.Label>Term/Duration of the Instrument

                        </Form.Label>
                    </Col>
                    <Col xs={12} md={9}>
                        <Form.Control
                            type="number"
                            placeholder="Number of Months/Years"
                            value={formData.FinancialText_Duration_Instrument || ""}
                            onChange={(e) => onInputChange("FinancialText_Duration_Instrument", e.target.value)}
                            required
                        />
                    </Col>
                </Row>

            </>}

            <Row className="mb-4 align-items-center">
                <Col xs={12} md={3}>
                    <Form.Label>Issuing Bank Name</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                    <Form.Control
                        type="text"
                        placeholder="Enter Bank Name"
                        value={formData.FinancialBankName || ""}
                        onChange={(e) => onInputChange("FinancialBankName", e.target.value)}
                        required
                    />
                </Col>
            </Row>


            {
                IssuanceForm ? <>
                    <p>Beneficiary Bank Details (if applicable):
                    </p>

                    <Row className="mb-4 align-items-center">
                        <Col xs={12} md={3}>
                            <Form.Label> Bank Name
                            </Form.Label>
                        </Col>
                        <Col xs={12} md={9}>
                            <Form.Control
                                type="text"
                                placeholder="Enter Bank Name"
                                value={formData.FinancialBankName2 || ""}
                                onChange={(e) => onInputChange("FinancialBankName2", e.target.value)}
                                required
                            />
                        </Col>
                    </Row>
                    <Row className="mb-4 align-items-center">
                        <Col xs={12} md={3}>
                            <Form.Label> SWIFT/BIC Code
                            </Form.Label>
                        </Col>
                        <Col xs={12} md={9}>
                            <Form.Control
                                type="text"
                                placeholder="Enter Bank Name"
                                value={formData.FinancialSwiftBicCode || ""}
                                onChange={(e) => onInputChange("FinancialSwiftBicCode", e.target.value)}
                                required
                            />
                        </Col>
                    </Row>
                    <Row className="mb-4 align-items-center">
                        <Col xs={12} md={3}>
                            <Form.Label>IBAN/Account Number
                            </Form.Label>
                        </Col>
                        <Col xs={12} md={9}>
                            <Form.Control
                                type="text"
                                placeholder="Enter IBAN Number"
                                value={formData.FinancialIBanNumber || ""}
                                onChange={(e) => onInputChange("FinancialIBanNumber", e.target.value)}
                                required
                            />
                        </Col>
                    </Row>
                    <p>Beneficiary Contact Information:
                    </p>


                    <Row className="mb-4 align-items-center">
                        <Col md={6}>
                            <Row className="align-items-center">
                                <Col xs={12} md={3}>
                                    <Form.Label>Name</Form.Label>
                                </Col>
                                <Col xs={12} md={9}>
                                    <Form.Control
                                        name="email"
                                        type="email"
                                        placeholder="Enter your Name"
                                        value={formData.FIDEmail}
                                        onChange={(e) => onInputChange("FIDEmail", e.target.value)}
                                        required
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row className="align-items-center">
                                <Col xs={12} md={3}>
                                    <Form.Label>Phone</Form.Label>
                                </Col>
                                <Col xs={12} md={9}>
                                    <PhoneInput
                                        name="FIDPhone"
                                        type="text"
                                        defaultCountry="US"
                                        className="border-0"
                                        placeholder="Enter your phone number"
                                        value={formData.FIDPhone}
                                        onChange={(value) => onInputChange("FIDPhone", value)}
                                        required
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>


                    <Row className="mb-4 align-items-center">
                        <Col md={3}>
                            <Form.Label>Email</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control
                                name="email"
                                type="email"
                                placeholder="Enter your email"
                                value={formData.FIDEmail}
                                onChange={(e) => onInputChange("FIDEmail", e.target.value)}
                                required
                            />
                        </Col>
                    </Row>



                </> : <> <Row className="mb-4 align-items-center">
                    <Col xs={12} md={3}>
                        <Form.Label>Instrument Status</Form.Label>
                    </Col>
                    <Col xs={12} md={9} className="d-flex flex-wrap">
                        {["Already Issued", "To be Issued"].map((type) => (
                            <Form.Check
                                key={type}
                                type="radio"
                                name="accountType"
                                value={type}
                                label={type}
                                id={type}
                                checked={formData.FinancialInstrumentStatus === type}
                                onChange={(e) => onInputChange("FinancialInstrumentStatus", e.target.value)}
                                className="me-3"
                                required
                            />
                        ))}
                    </Col>
                </Row>
                    <Row className="mb-4 align-items-center">
                        <Col md={3}>
                            <Form.Label>If already issued, please upload a copy</Form.Label>
                        </Col>
                        <Col md={9}>
                            <div
                                className="file-uploader pointer col-lg-5 col-12 d-flex align-items-center justify-content-center text-center p-2 border rounded bg-light cursor-pointer"
                                onClick={() => document.getElementById("FinancialDocs").click()}
                            >
                                <span className="me-2">
                                    <FileIcons />
                                </span>
                                <span>Upload File</span>
                            </div>
                            <Form.Control
                                type="file"
                                id="FinancialDocs"
                                className="d-none"

                                onChange={(e) => handleFileUpload("FinancialDocs", e)}
                            />
                            <small className="text-muted d-block mt-2">
                                (Maximum Size of JPG/PNG should be 5.0 MB Dimension: 413 px x 531 px)
                            </small>
                            {formData.FinancialDocs && formData.FinancialDocs.type === "image" && (
                                <img
                                    src={formData.FinancialDocs.url}
                                    alt="Uploaded Preview"
                                    className="img-fluid mt-3"
                                    style={{ maxWidth: "200px", maxHeight: "200px", objectFit: "cover" }}
                                />
                            )}
                            {formData.FinancialDocs && formData.FinancialDocs.type === "file" && (
                                <p className="mt-3 text-muted">
                                    Uploaded File: <strong>{formData.FinancialDocs.name}</strong>
                                </p>
                            )}
                        </Col>
                    </Row>

                    <Row className="mb-4 align-items-center">
                        <Col xs={12} md={3}>
                            <Form.Label>Jurisdiction of Issuance</Form.Label>
                        </Col>
                        <Col xs={12} md={9}>
                            <Form.Control
                                type="text"
                                placeholder="Enter Bank Name"
                                value={formData.FinancialJurisdictionOfIssuance || ""}
                                onChange={(e) => onInputChange("FinancialJurisdictionOfIssuance", e.target.value)}
                                required
                            />
                        </Col>
                    </Row>

                    <Row className="mb-4 align-items-center">
                        <Col xs={12} md={3}>
                            <Form.Label>Ownership Status</Form.Label>
                        </Col>
                        <Col xs={12} md={9} className="d-flex flex-wrap">
                            {["Owner", "Third-Party Introduction"].map((type) => (
                                <Form.Check
                                    key={type}
                                    type="radio"
                                    name="FinancialOwnerShipStatus"
                                    value={type}
                                    label={type}
                                    id={type}
                                    checked={formData.FinancialOwnerShipStatus === type}
                                    onChange={(e) => onInputChange("FinancialOwnerShipStatus", e.target.value)}
                                    className="me-3"
                                    required
                                />
                            ))}
                        </Col>
                    </Row>
                    <Row className="mb-4 align-items-center">
                        <Col xs={12} md={3}>
                            <Form.Label>Purpose of Funding</Form.Label>
                        </Col>
                        <Col xs={12} md={9} className="d-flex flex-wrap">
                            {["Trade Finance", "Project Finance", "Other"].map((type) => (
                                <Form.Check
                                    key={type}
                                    type="radio"
                                    name="FinancialPurposeOfFunding"
                                    value={type}
                                    label={type}
                                    id={type}
                                    checked={formData.FinancialPurposeOfFunding === type}
                                    onChange={(e) => handleOtherInputToggle("FinancialPurposeOfFunding", e.target.value)}
                                    className="me-3"
                                    required
                                />
                            ))}
                            {showOtherInput?.FinancialPurposeOfFunding && (
                                <Form.Control
                                    type="text"
                                    value={formData.OtherFinancialPurposeOfFunding || ""}
                                    onChange={(e) => onInputChange("OtherFinancialPurposeOfFunding", e.target.value)}
                                    className="mt-2"
                                />
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4 align-items-center">
                        <Col xs={12} md={3}>
                            <Form.Label>Loan type preferred</Form.Label>
                        </Col>
                        <Col xs={12} md={9} className="d-flex flex-wrap">
                            {["Non-Recourse", "Fixed Rate"].map((type) => (
                                <Form.Check
                                    key={type}
                                    type="radio"
                                    name="FinancialLoadTypePrefered"
                                    value={type}
                                    label={type}
                                    id={type}
                                    checked={formData.FinancialLoadTypePrefered === type}
                                    onChange={(e) => onInputChange("FinancialLoadTypePrefered", e.target.value)}
                                    className="me-3"
                                    required
                                />
                            ))}
                        </Col>
                    </Row>
                    <Row className="mb-4 align-items-center">
                        <Col xs={12} md={3}>
                            <Form.Label>Desired Loan-to-Value (LTV) Ratio</Form.Label>
                        </Col>
                        <Col xs={12} md={9}>
                            <Form.Control
                                type="text"
                                placeholder="Enter Bank Name"
                                value={formData.FinancialDesiredLTV || ""}
                                onChange={(e) => onInputChange("FinancialDesiredLTV", e.target.value)}
                                required
                            />
                        </Col>
                    </Row>
                    <Row className="mb-4 align-items-center">
                        <Col xs={12} md={3}>
                            <Form.Label>Issuer Status</Form.Label>
                        </Col>
                        <Col xs={12} md={9} className="d-flex flex-wrap">
                            {["Self Issuer", "Third Party Issuer"].map((type) => (
                                <Form.Check
                                    key={type}
                                    type="radio"
                                    name="FinancialIssuerStatus"
                                    value={type}
                                    label={type}
                                    id={type}
                                    checked={formData.FinancialIssuerStatus === type}
                                    onChange={(e) => onInputChange("FinancialIssuerStatus", e.target.value)}
                                    className="me-3"
                                    required
                                />
                            ))}
                        </Col>
                    </Row></>
            }
        </div>
    );
};

export default FIDForm;
