import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { CountrySelect } from "react-country-state-city";
import { Link } from "react-router-dom";

const CompilanceForm = ({ formData, onInputChange, IssuanceForm }) => {
    const declarations = [
        "I declare that the information provided is true and accurate to the best of my knowledge.",
        "I consent to Truvest Capital Market conducting due diligence and background checks as required.",
        <>
            I have read and agree to the{" "}
            <Link
                to="/termsofservice"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#007bff", textDecoration: "underline" }}
            >
                Terms and Conditions
            </Link>{" "}
            and{" "}
            <Link
                to="/privacypolicy"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#007bff", textDecoration: "underline" }}
            >
                Privacy Policy
            </Link>.
        </>
    ];



    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        onInputChange(name, type === "checkbox" ? checked : value);
    };

    return (
        <div>
            <h5 className="fw-bold mb-4">

                {IssuanceForm ? 8 : 7}. Compliance and Declarations</h5>
            {IssuanceForm ? <>
                <p>Anti-Money Laundering (AML) Compliance:</p>
                <Row className="mb-4 align-items-center">
                    <Col xs={12}>
                        <Form.Label>Have you ever been convicted of a financial crime? </Form.Label>
                    </Col>
                    <Col xs={12} className="d-flex">
                        {["Yes", "No"].map((type) => (
                            <Form.Check
                                key={type}
                                type="radio"
                                name="Compliancefinancialcrime"
                                value={type}
                                label={type}
                                id={`Compliancefinancialcrime-${type}`}
                                checked={formData.Compliancefinancialcrime === type}
                                onChange={handleChange}
                                className="me-3"
                                required
                            />
                        ))}
                    </Col>
                </Row>
                <Row className="mb-4 align-items-center">
                    <Col xs={12}>
                        <Form.Label>Are you currently under investigation for any financial misconduct? </Form.Label>
                    </Col>
                    <Col xs={12} className="d-flex ">
                        {["Yes", "No"].map((type) => (
                            <Form.Check
                                key={type}
                                type="radio"
                                name="Compliancefinancialmisconduct"
                                value={type}
                                label={type}
                                id={`Compliancefinancialmisconduct-${type}`}
                                checked={formData.Compliancefinancialmisconduct === type}
                                onChange={handleChange}
                                className="me-3"
                                required
                            />
                        ))}
                    </Col>
                </Row>

            </> : <>

                <Row className="mb-4 align-items-center">
                    <Col xs={12} md={3}>
                        <Form.Label>Politically exposed person (PEP) Status</Form.Label>
                    </Col>
                    <Col xs={12} md={9}>
                        <Form.Control
                            type="text"
                            placeholder="Enter PEP Status"
                            name="CompliancePEPStatus"
                            value={formData.CompliancePEPStatus || ""}
                            onChange={handleChange}
                            required
                        />
                    </Col>
                </Row>

                <Row className="mb-4 align-items-center">
                    <Col xs={12}>
                        <Form.Label>Are you or any immediate family member a PEP? </Form.Label>
                    </Col>
                    <Col xs={12} md={9} className="d-flex flex-wrap offset-md-3">
                        {["Yes", "No"].map((type) => (
                            <Form.Check
                                key={type}
                                type="radio"
                                name="CompliancePEPFamilyMember"
                                value={type}
                                label={type}
                                id={`CompliancePEPFamilyMember-${type}`}
                                checked={formData.CompliancePEPFamilyMember === type}
                                onChange={handleChange}
                                className="me-3"
                                required
                            />
                        ))}
                    </Col>
                </Row>
                <h6 className="fw-semibold mb-4">If Yes, provide details: [Position, Country, Relationship]</h6>
                <Row className="mb-4 align-items-center">
                    <Col xs={12} md={3}>
                        <Form.Label>Position</Form.Label>
                    </Col>
                    <Col xs={12} md={9}>
                        <Form.Control
                            type="text"
                            placeholder="Enter Position"
                            name="CompliancePosition"
                            value={formData.CompliancePosition || ""}
                            onChange={handleChange}
                            required
                        />
                    </Col>
                </Row>
                <Row className="mb-4 align-items-center">
                    <Col xs={12} md={3}>
                        <Form.Label>Country</Form.Label>
                    </Col>
                    <Col xs={12} md={9}>
                        <CountrySelect
                            name="ComplianceCountry"
                            value={formData.ComplianceCountry || ""}
                            onChange={(value) => onInputChange("ComplianceCountry", value?.name)}
                            required
                        />
                    </Col>
                </Row>
                <Row className="mb-4 align-items-center">
                    <Col xs={12} md={3}>
                        <Form.Label>Relationship</Form.Label>
                    </Col>
                    <Col xs={12} md={9}>
                        <Form.Control
                            type="text"
                            placeholder="Enter Relationship"
                            name="ComplianceRelationship"
                            value={formData.ComplianceRelationship || ""}
                            onChange={handleChange}
                            required
                        />
                    </Col>
                </Row>

                <h6 className="fw-semibold mb-4">Anti-Money Laundering (AML) Compliance</h6>
                <Row className="mb-4 align-items-center">
                    <Col xs={12}>
                        <Form.Label>Have you ever been convicted of a financial crime?</Form.Label>
                    </Col>
                    <Col xs={12} md={9} className="d-flex flex-wrap offset-md-3">
                        {["Yes", "No"].map((type) => (
                            <Form.Check
                                key={type}
                                type="radio"
                                name="CompliancefinancialCrime"
                                value={type}
                                label={type}
                                id={`CompliancefinancialCrime-${type}`}
                                checked={formData.CompliancefinancialCrime === type}
                                onChange={handleChange}
                                className="me-3"
                                required
                            />
                        ))}
                    </Col>
                </Row>

                <Row className="mb-4 align-items-center">
                    <Col xs={12}>
                        <Form.Label>Are you currently under investigation for any financial misconduct?</Form.Label>
                    </Col>
                    <Col xs={12} md={9} className="d-flex flex-wrap offset-md-3">
                        {["Yes", "No"].map((type) => (
                            <Form.Check
                                key={type}
                                type="radio"
                                name="CompliancefinancialMisconduct"
                                value={type}
                                label={type}
                                id={`CompliancefinancialMisconduct-${type}`}
                                checked={formData.CompliancefinancialMisconduct === type}
                                onChange={handleChange}
                                className="me-3"
                                required
                            />
                        ))}
                    </Col>
                </Row>


            </>
            }

            <h6 className="fw-semibold mb-4">Declarations</h6>
            <Row className="mb-4 ps-lg-2">
                {declarations.map((declaration, index) => (
                    <Form.Check
                        key={index}
                        type="checkbox"
                        required
                        label={declaration}
                        name={`ComplianceDeclaration-${index}`}
                        id={`ComplianceDeclaration-${index}`}
                        checked={formData[`ComplianceDeclaration-${index}`] || false}
                        onChange={handleChange}
                        className="blue-checkbox mb-4"
                    />
                ))}
            </Row>

        </div>
    );
};

export default CompilanceForm;
