import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { CountrySelect } from "react-country-state-city";
import DatePicker from "react-datepicker";

const PersonalInformationForm = ({ formData, onInputChange, IssuanceForm }) => {
    return (
        <div>
            <h5 className="fw-bold mb-4">1. Personal Information</h5>

            <Row className="mb-4 align-items-center">
                <Col md={3}>
                    <Form.Label>Full Name</Form.Label>
                </Col>
                <Col md={9}>
                    <Form.Control
                        name="fullName"
                        type="text"
                        placeholder="Enter your full name"
                        value={formData.personalInfofullName}
                        onChange={(e) => onInputChange("personalInfofullName", e.target.value)}
                        required
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <Row className="mb-4 align-items-center">
                        <Col md={6}>
                            <Form.Label>Date of Birth</Form.Label>
                        </Col>
                        <Col md={6}>
                            <DatePicker
                                selected={formData.personalInfodob}
                                onChange={(date) => onInputChange("personalInfodob", date)}
                                className="form-control"
                                placeholderText="DD/MM/YYYY"
                                dateFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"
                                required
                            />
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row className="mb-4 align-items-center">
                        <Col md={6}>
                            <Form.Label>Nationality</Form.Label>
                        </Col>
                        <Col md={6}>
                            <CountrySelect
                                required
                                placeHolder="Select Country"
                                value={formData.personalInfonationality}
                                onChange={(value) => onInputChange("personalInfonationality", value?.name)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {IssuanceForm &&
                <Row className="align-items-center mb-4">
                    <Col md={3}>
                        <Form.Label>Place of Birth</Form.Label>
                    </Col>
                    <Col md={3}>
                        <CountrySelect
                            required
                            placeHolder="Select Country"
                            value={formData.personalInfoPlaceofbirth}
                            onChange={(value) => onInputChange("personalInfoPlaceofbirth", value?.name)}
                        />
                    </Col>
                </Row>
            }
            <Row className="mb-4">
                <Col md={3}>
                    <Form.Label>Gender</Form.Label>
                </Col>
                <Col md={9} className="d-flex ">
                    <Form.Check
                        type="radio"
                        name="personalInfogender"
                        value="Male"
                        label="Male"
                        id="male"
                        checked={formData.personalInfogender === "Male"}
                        onChange={(e) => onInputChange("personalInfogender", e.target.value)}
                        className="me-3"
                        required
                    />
                    <Form.Check
                        type="radio"
                        name="personalInfogender"
                        value="Female"
                        label="Female"
                        id="female"
                        checked={formData.personalInfogender === "Female"}
                        onChange={(e) => onInputChange("personalInfogender", e.target.value)}
                        required
                    />
                </Col>
            </Row>
        </div>
    );
};

export default PersonalInformationForm;
