import React from 'react'
import { Container } from 'react-bootstrap'
import MonetizationForm from '../components/MonetizationForm'
import PageHeading from '../components/shared/PageHeading'
const Monetization = () => {
    return (
        <div style={{
            backgroundColor: "#FFFFFF"
        }}>
            <Container className='py-5'>
                <PageHeading heading="Monetization" />
                <p className='mb-4'>To facilitate the monetization of a financial instrument through Truvest Capital Market, clients are required to provide comprehensive information to ensure compliance with regulatory standards and to tailor services effectively. Below is a detailed web form outlining the necessary fields:</p>
                <h1 className="text-uppercase fs-4 color-primary service-card  mb-4">Client Information Form for Financial Instrument Monetization </h1>
                <MonetizationForm />
            </Container>
        </div>
    )
}

export default Monetization