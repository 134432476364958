import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FileIcons } from "../assets/SVG";

const FinancialCapacity = ({ formData, onInputChange }) => {
    const handleFileUpload = (key, e) => {
        const file = e.target.files[0];
        if (!file) return;

        const fileSizeLimit = 5 * 1024 * 1024;
        const allowedImageTypes = ["image/jpeg", "image/png"];
        const isImage = allowedImageTypes.includes(file.type);

        if (file.size > fileSizeLimit) {
            alert("File size exceeds 5MB limit.");
            return;
        }

        const fileData = {
            name: file.name,
            type: isImage ? "image" : "file",
            file,
        };

        if (isImage) {
            fileData.url = URL.createObjectURL(file);
        }

        onInputChange(key, fileData);
    };

    const [showOtherInput, setShowOtherInput] = useState({
        FinancialPurposeOfInstrument: false,
        FinancialTypeOfInstrument: false,
    });

    const handleOtherInputToggle = (field, value) => {
        onInputChange(field, value);
        setShowOtherInput((prev) => ({
            ...prev,
            [field]: value === "Other",
        }));
    };
    return (
        <div>
            <h5 className="fw-bold mb-4">7. Financial Capacity (Revised)</h5>
            <Row className="mb-4 align-items-center">
                <Col xs={12} lg={3}>
                    <Form.Label>How Much Capital Do You Have to Facilitate the Issuance?
                    </Form.Label>
                </Col>
                <Col xs={12} lg={9}>
                    <Form.Control
                        type="number"
                        placeholder="Enter Amount"
                        value={formData.FinancialAmount || ""}
                        onChange={(e) => onInputChange("FCAmount", e.target.value)}
                        required
                    />
                </Col>
            </Row>
            <Row className="mb-4 align-items-center">
                <Col xs={12} md={3}>
                    <Form.Label>Source of Funds for Facilitating Issuance
                    </Form.Label>
                </Col>
                <Col xs={12} md={6} className="d-flex flex-wrap">
                    {["Personal Savings", "Business Funds", "Loan", "Other"].map((type) => (
                        <Form.Check
                            key={type}
                            type="radio"
                            name="FinancialPurposeOfInstrument"
                            value={type}
                            label={type}
                            id={type}
                            checked={formData.FinancialPurposeOfInstrument === type}
                            onChange={(e) =>
                                handleOtherInputToggle("FinancialPurposeOfInstrument", e.target.value)
                            }
                            className="me-3"
                            required
                        />
                    ))}
                    {showOtherInput?.FinancialPurposeOfInstrument && (
                        <Form.Control
                            type="text"
                            value={formData.OtherFinancialPurposeOfInstrument || ""}
                            onChange={(e) => onInputChange("OtherFinancialPurposeOfInstrument", e.target.value)}
                            className="mt-2"
                        />
                    )}
                </Col>
            </Row>
            <Row className="mb-4 align-items-center">
                <Col md={3}>
                    <Form.Label>Additional Collateral or Security</Form.Label>
                </Col>
                <Col md={9}>
                    <div
                        className="file-uploader pointer col-lg-5 col-12 d-flex align-items-center justify-content-center text-center p-2 border rounded bg-light cursor-pointer"
                        onClick={() => document.getElementById("IdentificationDocsFile1").click()}
                    >
                        <span className="me-2">
                            <FileIcons />
                        </span>
                        <span>Upload File</span>
                    </div>
                    <Form.Control
                        type="file"
                        id="IdentificationDocsFile1"
                        className="d-none"
                        onChange={(e) => handleFileUpload("IdentificationDocsFile1", e)}
                    />
                    <small className="text-muted d-block mt-2">
                        (Maximum Size of JPG/PNG should be 5.0 MB Dimension: 413 px x 531 px)
                    </small>
                    {formData.IdentificationDocsFile1 && formData.IdentificationDocsFile1.type === "image" && (
                        <img
                            src={formData.IdentificationDocsFile1.url}
                            alt="Uploaded Preview"
                            className="img-fluid mt-3"
                            style={{ maxWidth: "200px", maxHeight: "200px", objectFit: "cover" }}
                        />
                    )}
                    {formData.IdentificationDocsFile1 && formData.IdentificationDocsFile1.type === "file" && (
                        <p className="mt-3 text-muted">
                            Uploaded File: <strong>{formData.IdentificationDocsFile1.name}</strong>
                        </p>
                    )}
                </Col>
            </Row>
            <Row className="mb-4 align-items-center">
                <Col md={3}>
                    <Form.Label>Preferred Term Payment Arrangement</Form.Label>
                </Col>
                <Col xs={12} md={9} className="d-flex flex-wrap">
                    {["UpFront", "Deferred Payment", "Other"].map((type) => (
                        <Form.Check
                            key={type}
                            type="radio"
                            name="FinancialTypeOfInstrument"
                            value={type}
                            label={type}
                            id={type}
                            checked={formData.FinancialTypeOfInstrument === type}
                            onChange={(e) =>
                                handleOtherInputToggle("FinancialTypeOfInstrument", e.target.value)
                            }
                            className="me-3"
                            required
                        />
                    ))}
                    {showOtherInput?.FinancialTypeOfInstrument && (
                        <Form.Control
                            type="text"
                            value={formData.OtherFinancialType || ""}
                            onChange={(e) => onInputChange("OtherFinancialType", e.target.value)}
                            className="mt-2"
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default FinancialCapacity;
