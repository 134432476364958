import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { CountrySelect } from "react-country-state-city";

const BankingInformation = ({ formData, onInputChange, IssuanceForm }) => {
    const [showOtherInput, setShowOtherInput] = useState({ BankAccountType: false });

    const handleOtherInputToggle = (field, value) => {
        onInputChange(field, value);
        setShowOtherInput((prev) => ({
            ...prev,
            [field]: value === "Other",
        }));
    };
    return (
        <div>
            <h5 className="fw-bold mb-4">5. Banking and Financial Capacity Information
            </h5>

            {/* Bank Name */}
            <Row className="mb-4 align-items-center">
                <Col xs={12} md={3}>
                    <Form.Label>Bank Name</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                    <Form.Control
                        type="text"
                        placeholder="Enter Bank Name"
                        value={formData.BankName || ""}
                        onChange={(e) => onInputChange("BankName", e.target.value)}
                        required
                    />
                </Col>
            </Row>

            {/* Bank Address */}
            <Row className="mb-4 align-items-center">
                <Col xs={12} md={3}>
                    <Form.Label>Bank Address</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                    <Form.Control
                        type="text"
                        placeholder="Enter Bank Address"
                        value={formData.BankAddress || ""}
                        onChange={(e) => onInputChange("BankAddress", e.target.value)}
                        required
                    />
                </Col>
            </Row>

            {

                IssuanceForm
                &&
                <>
                    <Row className="mb-4 align-items-center">
                        <Col md={3}>
                            <Form.Label>Street Address</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control
                                name="BankStreetAddress"
                                type="text"
                                placeholder="Enter your street address"
                                value={formData.BankStreetAddress}
                                onChange={(e) => onInputChange("BankStreetAddress", e.target.value)}
                                required
                            />
                        </Col>
                    </Row>

                    {/* City */}
                    <Row className="mb-4 align-items-center">
                        <Col md={3}>
                            <Form.Label>City</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control
                                name="BankCity"
                                type="text"
                                placeholder="Enter your city"
                                value={formData.BankCity}
                                onChange={(e) => onInputChange("BankCity", e.target.value)}
                                required
                            />
                        </Col>
                    </Row>

                    {/* State/Province */}
                    <Row className="mb-4 align-items-center">
                        <Col md={3}>
                            <Form.Label>State/Province</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control
                                name="BankState"
                                type="text"
                                placeholder="Enter your state/province"
                                value={formData.BankState}
                                onChange={(e) => onInputChange("BankState", e.target.value)}
                                required
                            />
                        </Col>
                    </Row>

                    {/* Postal Code and Country */}
                    <Row>
                        <Col>
                            <Row className="mb-4 align-items-center">
                                <Col md={6}>
                                    <Form.Label>Postal Code</Form.Label>
                                </Col>
                                <Col md={6}>
                                    <Form.Control
                                        name="BankPostalCode"
                                        type="text"
                                        placeholder="Enter postal code"
                                        value={formData.BankPostalCode}
                                        onChange={(e) => onInputChange("BankPostalCode", e.target.value)}
                                        required
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className="mb-4 align-items-center">
                                <Col md={6}>
                                    <Form.Label>Country</Form.Label>
                                </Col>
                                <Col md={6}>
                                    <CountrySelect
                                        required
                                        placeHolder="Select Country"
                                        value={formData.BankCountry}
                                        onChange={(value) => onInputChange("BankCountry", value?.name)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            }

            <Col className="mb-4" xs={12} md={6}>
                <Row className="align-items-center">
                    <Col md={6}>
                        <Form.Label>SWIFT/BIC Code
                        </Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Control
                            type="number"
                            placeholder="Enter SWIFT/BIC Code"
                            value={formData.BankSwift_BIC_CODE || ""}
                            onChange={(e) => onInputChange("BankSwift_BIC_CODE", e.target.value)}
                            required
                        />
                    </Col>
                </Row>
            </Col>
            <Row className="mb-4">

                <Col xs={12} md={6}>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <Form.Label>Account Number</Form.Label>
                        </Col>
                        <Col md={6}>
                            <Form.Control
                                type="number"
                                placeholder="Enter Account Number"
                                value={formData.BankAccountNumber || ""}
                                onChange={(e) => onInputChange("BankAccountNumber", e.target.value)}
                                required
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={6}>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <Form.Label>IBAN</Form.Label>
                        </Col>
                        <Col md={6}>
                            <Form.Control
                                type="number"
                                placeholder="Enter IBAN"
                                className="col-md-6"
                                value={formData.BankingIban || ""}
                                onChange={(e) => onInputChange("BankingIban", e.target.value)}
                                required
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* Account Type */}
            <Row className="mb-4 align-items-center">
                <Col xs={12} md={3}>
                    <Form.Label>Account Type</Form.Label>
                </Col>
                <Col xs={12} md={9} className="d-flex flex-wrap">
                    {["Current", "Savings", "Other"].map((type) => (
                        <Form.Check
                            key={type}
                            type="radio"
                            name="BankAccountType"
                            value={type}
                            label={type}
                            id={type}
                            checked={formData.BankAccountType === type}
                            onChange={(e) =>
                                handleOtherInputToggle("BankAccountType", e.target.value)
                            } className="me-3"
                            required
                        />
                    ))}
                    {showOtherInput?.BankAccountType && (
                        <Form.Control
                            type="text"
                            name="BankAccountType"
                            value={formData.OtherBankAccountType || ""}
                            onChange={(e) => onInputChange("OtherBankAccountType", e.target.value)}
                            className="mt-2"
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default BankingInformation;
