import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const AdditionalInfoForm = ({ formData, onInputChange, IssuanceForm }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        onInputChange(name, value);
    };

    return (
        <div>
            <h5 className="fw-bold mb-4">{IssuanceForm ? 11 : 10}. Additional Information</h5>

            <Row className="mb-4 align-items-center">
                <Col xs={12} className="mb-4">
                    <Form.Label>Please provide any additional information or comments:</Form.Label>
                </Col>
                <Col xs={12}>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        name="additionalInfo"
                        placeholder="Enter your comments or additional details here"
                        value={formData.additionalInfo || ""}
                        onChange={handleChange}
                        required
                    />
                </Col>
            </Row>
        </div>
    );
};

export default AdditionalInfoForm;
