import React from 'react'
import { Route, Routes } from 'react-router-dom'
import BookingPage from './pages/BookingPage'
import Contact from './pages/Contact'
import CookieConsent from './pages/CookieConsent'
import Disclaimer from './pages/Disclaimer'
import ForgotPassword from './pages/ForgotPassword'
import Home from './pages/Home'
import Issuance from './pages/Issuance'
import Landing from './pages/Landing'
import Login from './pages/Login'
import PrivacyPolicy from './pages/PrivacyPolicy'
import SalesStep from './pages/SalesStep'
import Services1 from './pages/Services1'
import Services2 from './pages/Services2'
import Services3 from './pages/Services3'
import TermsOfService from './pages/TermsOfService'
import Transaction from './pages/Transaction'
import Transaction2 from './pages/Transaction2'
import Transaction3 from './pages/Transaction3'
import Transaction4 from './pages/Transaction4'
import Vedios from './pages/Vedios'
import CookiePolicy from './pages/CookiePolicy'
import Monetization from './pages/Monetization'

const Navigation = () => {
    return (
        <>
            <CookieConsent />
            <Routes>

                <Route path="/" element={<Home />}></Route>
                <Route path="/get-started" element={<Landing />}></Route>
                <Route path="/cookie" element={<CookiePolicy />}></Route>
                <Route path="/issuance" element={<Issuance />}></Route>
                <Route path="/monetization" element={<Monetization />}></Route>
                <Route path="/calandar" element={<SalesStep />}></Route>
                <Route path="/booking" element={<BookingPage />}></Route>
                <Route path="/service1" element={<Services1 />}></Route>
                <Route path="/service2" element={<Services2 />}></Route>
                <Route path="/service3" element={<Services3 />}></Route>
                <Route path="/transaction" element={<Transaction />}></Route>
                <Route path="/transaction3" element={<Transaction3 />}></Route>
                <Route path="/transaction4" element={<Transaction4 />}></Route>
                <Route path="/transaction2" element={<Transaction2 />}></Route>
                <Route path="/videos" element={<Vedios />}></Route>
                <Route path="/contact" element={<Contact />}></Route>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/forgetpassword" element={<ForgotPassword />}></Route>
                <Route path="/disclaimer" element={<Disclaimer />}></Route>
                <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
                <Route path="/termsofservice" element={<TermsOfService />}></Route>
            </Routes>
        </>
    )
}

export default Navigation